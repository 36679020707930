import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import MachinesModule from "@/store/modules/MachinesModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import CheckoutModule from "@/store/modules/CheckoutModule";
import PagarmeModule from "@/store/modules/PagarmeModule";
import CourseModule from "@/store/modules/CourseModule";
import ModuleModule from "@/store/modules/ModulesModule";
import VpnModule from "@/store/modules/VpnModule";
import FeedModule from "@/store/modules/FeedModule";
import Notification from "@/store/modules/Notification";
import LessonModule from "@/store/modules/LessonModule";
import LanguageModule from "@/store/modules/LanguageModule";
// @ts-ignore
import ScoreSystemModule from "@/store/modules/ScoreSystemModule";
import UserPublicModule from "@/store/modules/UserPublicModule";
import ChallengeModule from "@/store/modules/ChallengeModule";
// @ts-ignore
import InvoiceModule from "@/store/modules/InvoiceModule";
// @ts-ignore
import CertificateModule from "@/store/modules/CertificateModule";
// @ts-ignore
import CertificationModule from "@/store/modules/CertificationModule";
// @ts-ignore
import PathwaysModule from "@/store/modules/Pathways";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    MachinesModule,
    CheckoutModule,
    PagarmeModule,
    CourseModule,
    VpnModule,
    FeedModule,
    ModuleModule,
    Notification,
    LessonModule,
    ScoreSystemModule,
    UserPublicModule,
    ChallengeModule,
    LanguageModule,
    InvoiceModule,
    CertificateModule,
    CertificationModule,
    PathwaysModule
  },
});

export default store;
