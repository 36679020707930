import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import type { ModuleData } from "@/types/ModuleType";
import {AxiosResponse} from "axios";

@Module
export default class ModuleModule extends VuexModule {

    errors: {} = {};
    moduleData: ModuleData = {} as ModuleData;
    currentModule: ModuleData = {} as ModuleData;

    get getCurrentModule() {
        return this.currentModule;
    }
    //
    get getModuleData(): ModuleData {
        return this.moduleData;
    }

    @Action
    async [Actions.GET_MODULE_ALL]() {

        try {
            const response: AxiosResponse<any> = await ApiService.get("/academy?");
            this.context.commit(Mutations.SET_MODULE_DATA, response.data);
        } catch (error) {
            this.context.commit(Mutations.SET_MODULE_DATA, {});
        }
    }
    @Action
    async [Actions.GET_MODULE](id: number) {
        try {
            const response: AxiosResponse<any> = await ApiService.get("/academy/modules/"+id);
            this.context.commit(Mutations.SET_CURRENT_MODULE_DATA, response.data.data);
        } catch (error) {
            this.context.commit(Mutations.SET_CURRENT_MODULE_DATA, {});
        }
    }
    @Mutation
    [Mutations.SET_MODULE_DATA](moduleData: ModuleData) {
        if(moduleData.lessons) {
            this.moduleData.lessons = moduleData.lessons;
        }

        this.moduleData = moduleData;
    }

    @Mutation
    [Mutations.SET_CURRENT_MODULE_DATA](module: ModuleData) {
        this.currentModule = module;
    }

}