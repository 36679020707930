import ApiService from "@/core/services/ApiService";
import axios from "axios";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Pagarme {
  public_key_id: string;
  card: Card;
}
export interface Card {
  id: string;
  number: string;
  holder_name: string;
  exp_month: number;
  exp_year: number;
  cvv: string;
  last_four_digits: string;
  brand: string;
  label: string;
  installments: number;
}

export interface CardToken {
    id: string;
    type: string;
    created_at: string;
    expires_at: string;
    card: Card;
}

@Module
export default class PagarmeModule extends VuexModule {
  errors = {};
  pagarme = {} as Pagarme;
  card = {} as Card;
  cardToken = {} as CardToken;


  get getCurrentCardToken(): any {
    return this.cardToken;
  }

  get getCurrentCard(): any {
    return this.card;
}

  @Mutation
  [Mutations.SET_INSTALLMENTS](installments) {
    this.card.installments = installments;
  }

  @Mutation
  [Mutations.SET_CARD_DATA](card) {
  
    this.cardToken = card;
  
  }

  @Action
  async [Actions.GET_CARD_TOKEN](key) {
      //https://exchange-service.crowsec.com.br/
      await fetch("https://api.pagar.me/core/v5/tokens?appId=" + process.env.VUE_APP_PAGARME_PUBLIC_KEY, {
     
            // Adding method type
            method: "POST",
            
            // Adding body or contents to send
            body: JSON.stringify( {
        type: "card",
        card: {
        number: this.card.number.replace(/ /g, ""),
        holder_name: this.card.holder_name,
        exp_month: this.card.exp_month,
        exp_year: this.card.exp_year,
        cvv: this.card.cvv,
        }}),
            
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        
        // Converting to JSON
        .then(response => response.json())
        
        // Displaying results to console
        .then(json => {
            this.context.commit(Mutations.SET_CARD_DATA, json);
            
        });
    }

}
