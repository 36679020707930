import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { inputEmits } from "element-plus";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Pathway {
    id: string;
    name: string; // Nome do pathway
    short_description: string; // Breve descrição
    description: string; // Descrição completa
    image?: string; // Imagem (opcional)
    level: 'Beginner' | 'Intermediate' | 'Advanced'; // Nível (Beginner, Intermediate, Advanced)
    prerequisites?: Array<string>; // Pré-requisitos (opcional, array de strings)
    estimated_time?: string; // Tempo estimado (opcional)
    type: 
      | 'Offensive'
      | 'General'
      | 'Application Security'
      | 'Defensive'
      | 'Threat Intelligence'
      | 'Incident Response'
      | 'Forensics'
      | 'Reverse Engineering'
      | 'Hardware'
      | 'Mobile'
      | 'Cloud'
      | 'IOT'
      | 'Network'
      | 'Web'
      | 'Wireless'
      | 'OSINT'
      | 'Social Engineering'
      | 'Malware Analysis'
      | 'Cryptography'
      | 'Steganography'
      | 'Binary Analysis'
      | 'Programming'
      | 'Scripting'
      | 'DevOps'
      | 'Cloud Security'
      | 'Management'
      | 'Soft Skills'
      | 'Career'
      | 'Certification'
      | 'Other'; // Tipo do pathway
  }

@Module
export default class PathwaysModule extends VuexModule   {
  errors = {};
  pathways = {} as Pathway[];
  current_pathway = {} as Pathway;

  get getAllPathways(): Pathway[] {
    return this.pathways;
  }

  get getCurrentPathway(): Pathway {
    return this.current_pathway;
  }

  @Mutation
  [Mutations.SET_CURRENT_PATHWAY](pathway) {
    this.current_pathway = pathway;
  }

  @Mutation
  [Mutations.SET_PATHWAYS](pathways) {
    this.pathways = pathways;
  }
  
  @Action
  [Actions.GET_PATHWAYS]() {
    console.log("Getting pathways");
    ApiService.query("pathways", {}).then(({ data }) => {
        this.context.commit(Mutations.SET_PATHWAYS, data.data);
    });
  }

    @Action
    [Actions.GET_PATHWAY](id: string) {
        console.log("Getting pathway");
        ApiService.query(`pathways/${id}`, {}).then(({ data }) => {
            this.context.commit(Mutations.SET_CURRENT_PATHWAY, data.data);
        });
    }

}
