import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import store from "@/store";

@Module
export default class InvoiceModule extends VuexModule {
  errors = {};
  invoicesData  = [];
  currentInvoicePage = 1;


  @Action
  async [Actions.REFUND_INVOICE](invoice_id: number) {
    const params = { invoice_id };
    try {
      await ApiService.post('/invoice/refund', params as AxiosRequestConfig);
    } catch (error) {
      this.errors = error;
    }
  }

}