import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { inputEmits } from "element-plus";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Machine {
  id: string;
  name: string;
  type: string;
  os_name: string;
  xp: number;
  flags: number;
  dificulty: string;
  creator: Array<string>;
  first_blood: Array<string>;
  instance: Instance;
  activities: Array<Activity>;
  tags: Array<string>;
  percentage: number;
}

export interface IMachineMetadata {
  current_page: number;
  last_page: number;
  from: number;
  links: Array<string>;
  to: number;
  total: number;
  per_page: number;
}

export interface CurrentMachineList {
  machines: Array<Machine>;
}

export interface Activity {
  scoreboard: Record<string, unknown>;
  activity: Record<string, unknown>;
}
export interface Instance {
  id: number;
  is_active: boolean;
  startup: string;
  shutdown: string;
  machine_id: string;
  ip_address: string;
  user_id: number;
}

export interface MachineInfo {
  errors: unknown;
  machines: Machine;
}

@Module
export default class MachinesModule extends VuexModule implements MachineInfo {
  errors = {};
  machines = {} as Machine;
  current_machine = {} as Machine;
  current_machine_metadata = {} as IMachineMetadata;
  paginate_length = 9;

  get getAllMachines(): Machine {
    return this.machines;
  }

  get getCurrentMachine(): Machine {
    return this.current_machine;
  }
  get getMachineActivities(): Array<Activity> {
    return this.current_machine.activities;
  }
  get getMachineMetadata(): IMachineMetadata {
    return this.current_machine_metadata;
  }

  @Mutation
  [Mutations.SET_CURRENT_MACHINE](machine) {
    this.current_machine = machine;
  }

  @Mutation
  [Mutations.SET_CURRENT_MACHINE_CERTIFICATION](machine){
    this.current_machine = machine;
  }

  @Mutation
  [Mutations.SET_MACHINES](machines) {
    this.machines = machines;
  }
  @Mutation
  [Mutations.SET_MACHINES_METADATA](metadata) {
    this.current_machine_metadata = metadata;
  }

  @Mutation
  [Mutations.START_INSTANCE](id) {
   
    
    ApiService.post("labs/machines/" + id + "/start", {})
    .then(({ data }) => {
      this.context.commit(Mutations.SET_CURRENT_MACHINE, data.data);
    });
  }
  @Mutation
  [Mutations.STOP_INSTANCE](id) {
   
     ApiService.post("labs/machines/" + id + "/stop", {})
    .then(({ data }) => {
      this.context.commit(Mutations.SET_CURRENT_MACHINE, data.data);
    });
  }
  @Mutation
  [Mutations.SET_CURRENT_MACHINE_ACTIVITY](activity) {
    this.current_machine.activities = activity;
  }

  @Action
  [Actions.GET_MACHINES]({ type }: { type: string }) {
    ApiService.query("labs/machines?type=" + type , {}).then(({ data }) => {
      
      const perChunk = this.paginate_length // items per chunk    


      const result = data.data.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/perChunk)

          if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
          }

          resultArray[chunkIndex].push(item)
          
          return resultArray
      }, [])

      this.context.commit(Mutations.SET_MACHINES, result);


    });
  }

  @Action
  async [Actions.GET_MACHINES_FILTERED]({ type, dificulty, search, status, page }: { type: string, dificulty: string, search: string, status: string, page: string }) {
    
    const params = {
      type: type ? type : "default",
      dificulty: dificulty ? dificulty : "",
      progress: status ? status : "",
      search: search ?  search : ""
    }
    if(page) {
      params["page"] = page;
    }
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    await ApiService.query("labs/machines?"+queryString , {}).then(({ data }) => {
      
        this.context.commit(Mutations.SET_MACHINES, data.data);
        this.context.commit(Mutations.SET_MACHINES_METADATA, data.meta);
  
    });
  }

  @Action
  [Actions.GET_MACHINE]({ id }: { id: string }) {
    return ApiService.query("labs/machines/" + id, {})
      .then(({ data }) => {
        if (data && data.data) {
          this.context.commit(Mutations.SET_CURRENT_MACHINE, data.data);
          return data.data;
        } else {
          return null;
        }
      });
  }

  @Action
  [Actions.GET_MACHINE_CERTIFICATION]({ id }: { id: string }) {
    ApiService.query(`labs/machines/${id}/certification`, {}).then(({ data }) => {
      this.context.commit(Mutations.SET_CURRENT_MACHINE_CERTIFICATION, data.data);
    });
  }
  @Action
  [Actions.GET_MACHINE_ACTIVITY]({ id }: { id: string }) {
    ApiService.query("labs/machines/" + id + "/activities", {}).then(
      ({ data }) => {
        this.context.commit(Mutations.SET_CURRENT_MACHINE_ACTIVITY, data.data);
      }
    );
  }
}
