import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {AxiosResponse} from "axios";
import type {CourseData} from "@/types/CourseType";
import {LessonType} from "@/types/LessonType";
import {Quiz} from "@/types/QuizType";
import {Student} from "@/types/StudentType";


@Module
export default class CourseModule extends VuexModule {

    errros = {};
    courseData: CourseData = {} as CourseData;
    currentCourse: CourseData = {} as CourseData;

    get getCurrentCourse() {
        return this.currentCourse;
    }

    get getCourseData(): CourseData {
        return this.courseData;
    }
    get getLessons(): LessonType[] {
        return this.courseData.lessons;
    }
    get getQuiz(): Quiz {
        return this.courseData.quiz;
    }
    get getStudent(): Student {
        return this.courseData.Student;
    }

    @Action
    async [Actions.GET_ALL_COURSES]() {

        try {
            const response: AxiosResponse<any> = await ApiService.get("/academy/courses/list");
            this.context.commit(Mutations.SET_COURSE_DATA, response.data);
        } catch (error) {
            this.context.commit(Mutations.SET_COURSE_DATA, {});
        }
    }
    @Action
    async [Actions.GET_COURSE](id: string) {
        try {
            const response: AxiosResponse<any> = await ApiService.get("/academy/courses/"+id);
            this.context.commit(Mutations.SET_CURRENT_COURSE_DATA, response.data.data);
        } catch (error) {
            this.context.commit(Mutations.SET_CURRENT_COURSE_DATA, {});
        }
    }
    @Mutation
    [Mutations.SET_COURSE_DATA](courseData: CourseData) {
        if(courseData.lessons) {
            this.courseData.lessons = courseData.lessons;
        }

        this.courseData = courseData;
    }

    @Mutation
    [Mutations.SET_CURRENT_COURSE_DATA](course: CourseData) {
        this.currentCourse = course;
    }

}