import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import store from "@/store";
import type {CommentType, FeedModuleInfo, FeedType} from "@/types/FeedType";

@Module
export default class FeedModule extends VuexModule implements FeedModuleInfo {
  errors = {};
  feed: FeedType[] = [];
  showFeed = {};
  currentFeedPage = 1;
  feedEnded = false;
  isLoading = false;

  /**
   * Get authentification errors
   * @returns array
   */
  get getShowFeed() {
    return this.showFeed;
  }
  get getFeed() {
    return this.feed;
  }

  get getFeedPage(){
    return this.currentFeedPage;
  }
  get getFeedEnded(){
    return this.feedEnded;
  }

  get getIsLoading(){
    return this.isLoading;
  }
  @Mutation
  async [Mutations.SET_FEED](feed:FeedType[]) {
    
    if(feed.length == 0){
      this.feedEnded = true;
    }
    feed.forEach((element: FeedType) => {
      this.feed.push(element);
    });
    this.currentFeedPage++;
  }

  @Mutation
  async [Mutations.SET_SHOW_FEED](feed:FeedType) {
    this.showFeed = feed;
  }

  @Mutation 
  async [Mutations.REFRESH_PAGINATION](){
    this.currentFeedPage = 1;
  }

  @Mutation
  async [Mutations.SET_LOADING](status: boolean){
    this.isLoading = status;
  }

  @Mutation
  async [Mutations.REFRESH_FEED](id: number) {

    await ApiService.get("social/hacktivities/" + id).then(({ data }) => {
     
      this.feed.forEach((element: FeedType) => {
        if(element.id == id){
        
          this.feed[this.feed.indexOf(element)] = data.data;
        }
      });
    });
  }

  @Mutation
  async [Mutations.REFRESH_REACTIONS](id: number) {

    await ApiService.get("social/hacktivities/" + id).then(({ data }) => {
     
      this.feed.forEach((element: FeedType) => {
        if(element.id == id){
        
          this.feed[this.feed.indexOf(element)].reaction = data.data.reaction;
        }
      });
    });
  }

  @Mutation
  async [Mutations.REFRESH_COMMENTS](id) {

    await ApiService.get("social/comments/hacktivity/" + id).then(({ data }) => {

      let currentFeedId = data.data.commentable_id;

      this.feed[currentFeedId].comments.forEach((element: CommentType) => {
        if(element.id == id){
          this.feed[currentFeedId].comments[this.feed[currentFeedId].comments.indexOf(element)] = data.data
        
        }
      });
    });
  }

  @Mutation
  async [Mutations.REFRESH_COMMENTS_FROM_FEED](id) {

    await ApiService.get("social/hacktivities/" + id).then(({ data }) => {

      this.feed.forEach((element: FeedType) => {
        if(element.id == id){
          this.feed[this.feed.indexOf(element)].comments = data.data.comments;
        }
      });
    });
  }
  @Action
  async [Actions.GET_FEED](id) {
   
      const params = {
      };

      const page = this.currentFeedPage || 1;
      if(!this.feedEnded){
        await this.context.commit(Mutations.SET_LOADING, true);
        await ApiService.query("social/hacktivities/"+id+"?page="+page, params as AxiosRequestConfig)
          .then(async ({ data }) => {
            await this.context.commit(Mutations.SET_FEED, [data.data]);
            await this.context.commit(Mutations.SET_LOADING, false);
          })
          .catch(async ({ response }) => {
            await this.context.commit(Mutations.SET_LOADING, false);
          });
      }
   
  }

  @Action
  async [Actions.FEED_SHOW](id: string) {
    const params = {};
    await ApiService.query(`social/hacktivities/${id}`, params as AxiosRequestConfig)
      .then( ({ data }) => {
        this.context.commit(Mutations.SET_SHOW_FEED, [data.data]);
      })
      .catch( ({ response }) => {
        this.context.commit(Mutations.SET_SHOW_FEED, []);
      });
  }

  @Action
  async [Actions.COMMENT]({id: id, message: message} : {id: number, message: string}) {

    const params = {
      message: message
    };

    

    await ApiService.post("social/comment/hacktivity/" + id, params as AxiosRequestConfig)
      .then(async ({ data }) => {
        this.context.commit(Mutations.REFRESH_COMMENTS_FROM_FEED, id);
      })
      .catch(async ({ response }) => {
        await this.context.commit(Mutations.SET_LOADING, false);
      });

  }

  @Action
  async [Actions.GET_ALL_FEEDS]() {
   
      const params = {
      };

      const page = this.currentFeedPage || 1;
      if(!this.feedEnded){
        await this.context.commit(Mutations.SET_LOADING, true);
        await ApiService.query("social/hacktivities?page="+page, params as AxiosRequestConfig)
          .then(async ({ data }) => {         
            await this.context.commit(Mutations.SET_FEED, data.data);
            await this.context.commit(Mutations.SET_LOADING, false);
          })
          .catch(async ({ response }) => {
            await this.context.commit(Mutations.SET_LOADING, false);
          });
      }
   
  }
  @Action
  async [Actions.REACT]({type: type, react: react,  id: id} : {type: string, react: string,  id: number}) {
   
    const params = {
      type: react
    };
    await this.context.commit(Mutations.SET_LOADING, true);
    await ApiService.post(`/social/react/${type}/${id}`, params as AxiosRequestConfig)
      .then(async ({ data }) => {         
        if(type == "comment"){
          await this.context.commit(Mutations.REFRESH_COMMENTS, id);
          await this.context.commit(Mutations.SET_LOADING, false);
        }else{
          await this.context.commit(Mutations.REFRESH_REACTIONS, id);
          await this.context.commit(Mutations.SET_LOADING, false);
        }
      })
      .catch(async ({ response }) => {
        
        await this.context.commit(Mutations.SET_LOADING, false);
        
      });
  }

  @Action
  async [Actions.DELETE_COMMENT]({comment_id, hacktivity_id}) {
   
    await ApiService.delete("social/comments/hacktivity/" + comment_id)
      .then(async ({ data }) => {
        await this.context.commit(Mutations.REFRESH_COMMENTS_FROM_FEED, hacktivity_id);
      })
      .catch(async ({ response }) => {
        await this.context.commit(Mutations.SET_LOADING, false);
      });
  }

  @Action
  async [Actions.ADD_HACKTIVITY]({id: id, headline: headline} : {id: number, headline: string}) {
    const params = { headline };

    try {
      await ApiService.post(`social/comment/lesson/${id}`, params as AxiosRequestConfig).then(
          async ({ data }) => {
            await store.dispatch(Actions.GET_LESSON, id)
          })
    }catch (error){
      console.error('Error posting comment:', error);
      this.context.commit(Mutations.SET_LOADING, false);
    }
  }

  @Action
    async [Actions.DELETE_HACKTIVITY]({id: id, lessonId: lessonId} ) {
        try {
            await ApiService.delete(`social/hacktivities/${id}`).then(
                async ({ data }) => {
                  await store.dispatch(Actions.GET_LESSON, lessonId)
                })
        }catch (error){
            console.error('Error posting comment:', error);
            this.context.commit(Mutations.SET_LOADING, false);
        }
  }

  @Action
    async [Actions.FIXED_HACKTIVITY]({id: id, lessonId: lessonId} ) {
      try {
        // @ts-ignore
        await ApiService.put(`social/hacktivities/${id}/fix`).then(
            async ({ data }) => {
              await store.dispatch(Actions.GET_LESSON, lessonId)
            })
      }catch (error){
        console.error('Error posting comment:', error);
        this.context.commit(Mutations.SET_LOADING, false);
      }
    }
}



