import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import router from "@/router";

export interface User {
  name: string;
  email: string;
  password: string;
  avatar: string;
  instance: Instance;
  level: number;
  xp: number;
  country: string;
  language: string;
  bio: string;
  nick: string;
  is_premium: boolean;
  is_admin: boolean;
  github_url: string;
  linkedin_url: string;
}
export interface Instance {
  is_active: boolean;
  shutdown: string;
  machine_id: string;
  ip_address: string;
}
export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  /**
   * Get current user name
   */
  get getUserName() {
    return this.user.name;
  }
  get getUserInstance() {
    return this.user.instance;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
  }

  @Mutation
  [Mutations.SET_USER](user: User) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  async [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
  
    router.push("/sign-in");
  }

  @Mutation
  [Mutations.UPDATE_USER](user)
  {
    this.user = user;
    ApiService.put("account/profile", user)
    .then(({ data }) => {
    this.context.commit(Mutations.SET_AUTH, data);
    })
    .catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR, response.data.errors);
    });
  }
  
  @Action
  async [Actions.LOGIN](credentials) {
    return;

  }

  @Action
  async [Actions.LOGOUT]() {
    await ApiService.get("auth/logout");
    await this.context.commit(Mutations.PURGE_AUTH);
  
  }
  @Action
  [Actions.LOGIN_SSO](data) {
    return ApiService.post(`auth/${data.provider}`, data.credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data.data);
        this.context.dispatch(Actions.VERIFY_AUTH);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.message);
        
      });
  }
  
  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const params = {
        ...payload,
    };
    return ApiService.post("password/forgot", params)
      .then(({ data }) => {
        if(data.success) {
          return true;
        }else{
           throw "error";
        }
      })
      .catch(({ response }) => {
        throw "error";
      });
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
   
      const params = {};
      await ApiService.query("auth/verify", params as AxiosRequestConfig)
        .then(({ data }) => {
       
          
          this.context.commit(Mutations.SET_AUTH, data.data);
        })
        .catch(({ response }) => {
         
          this.context.commit(Mutations.PURGE_AUTH);
        });

    
   
  }
}
