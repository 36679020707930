import { Action, Mutation, Module, getModule, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import {AxiosRequestConfig} from "axios";

@Module
export default class CertificationModule extends VuexModule{
    countdown: number = 0;
    errors = {};

    get getCountdown(){
        return this.countdown;
    }//sendReport
    @Action
    async [Actions.ADD_SEND_REPORT](report: File){
        const formData = new FormData();
        formData.append('report', report);
        try {
            await ApiService.vueInstance.axios.post('/certification/start-send-report/1', formData);
        } catch (error) {
            this.errors = error;
        }
    }


}