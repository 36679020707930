import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import type {Challenge} from "@/types/ChallengeType";


@Module
export default class ChallengeModule extends VuexModule {
    errors = {};
    challenges = {} as Challenge;
    current_challenge = {} as Challenge;

    /**
     * Get all challenges
     * @returns Challenges
     */
    get getChallenges(): Challenge {
        return this.challenges;
    }
    get getCurentChallenge(): Challenge {
        return this.current_challenge;
    }

    @Action
    async [Actions.GET_ALL_CHALLENGES]() {
        try {
            const response: any = await ApiService.get('/academy/challenges/list');
           this.context.commit(Mutations.SET_CHALLENGES, response.data.data);
        } catch (error) {
            this.context.commit(Mutations.SET_CHALLENGES, {});
            this.errors = error;
         
        }
    }

    @Action
    async [Actions.GET_CHALLENGE](id: number) {

        try {
            const response: any = await ApiService.get('/academy/challenges/'+id);
           this.context.commit(Mutations.SET_CHALLENGES, response.data.data);
        } catch (error) {
            this.context.commit(Mutations.SET_CHALLENGES, {});
            this.errors = error;
           
        }

    }

    @Mutation
    [Mutations.SET_CHALLENGE](challenge: Challenge) {
        this.current_challenge = challenge;
    }

    @Mutation
    [Mutations.SET_CHALLENGES](challenges: Challenge) {
        this.challenges = challenges;
    }
}