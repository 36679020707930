import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, VuexModule, Mutation} from "vuex-module-decorators";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import type {CourseData} from "@/types/CourseType";

@Module
export default class CertificateModule extends VuexModule {
  errors = {};
  certificateResponse: any = {};

  get getCertificateResponse() {
    return this.certificateResponse;
  }

  @Action
  async [Actions.VERIFY_CERTIFICATE](validation_id: number) {
    const params = { validation_id };
    try {
       const response = await ApiService.post('/certificate/verify', params as AxiosRequestConfig);
       this.context.commit(Mutations.SET_CERTIFICATE, response);
    } catch (error) {
      this.context.commit(Mutations.SET_CERTIFICATE, {});
    }
  }

  @Mutation
  [Mutations.SET_CERTIFICATE](certificateResponse: any) {
    this.certificateResponse = certificateResponse;
  }
}