import { createRouter, RouteRecordRaw, createWebHistory } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/password/reset",
        name: "password-reset-validation",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset-Validation.vue")
      },
      {
        path: "/auth/email/verify",
        name: "verify-email",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/VerifyEmail.vue"),
      },
      {
        path: "/oauth/:provider",
        name: "oauth-provider",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Oauth.vue"),
        props: (route) => ({ provider: route.params.provider }),
      },
      {
        path: "/auth/:provider/callback",
        name: "oauth-provider-callback",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Oauth-Callback.vue"),
        props: (route) => ({ provider: route.params.provider }),
      },
      {
        path: "/privacy-and-terms",
        name: "privacy-and-terms",
        component: () => import("@/views/crafted/privacy/Index.vue"),
      }
    ],
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/crafted/course/base/Dashboard.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/starting-point/getting-started",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/starting-point/getting-started",
        name: "getting-started",
        component: () => import("@/views/Dashboard.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/laboratory/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/laboratory/competition-machines",
        name: "competition-machines",
        component: () =>
          import("@/views/crafted/championship/machines/MachinesList.vue"),
      },
      {
        path: "/laboratory/competition-machines/:machine_uuid",
        name: "competition-machines-show",
        component: () =>
          import("@/views/crafted/championship/machines/MachineShow.vue"),
        props: (route) => ({ uuid: route.params.machine_uuid }),
      },
      {
        path: "/laboratory/competition-challenges",
        name: "competition-challenges",
        component: () =>
          import("@/views/crafted/championship/challenges/ChallengesList.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/training/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/training/learning-paths",
        name: "learning-paths",
        component: () =>
          import("@/views/crafted/training/learning-paths/List.vue"),
      },
      {
        path: "/training/learning-paths/:id",
        name: "learning-paths-show",
        component: () =>
          import("@/views/crafted/training/learning-paths/Show.vue"),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: "/training/learning-paths/:id/lesson/:lesson_id",
        name: "learning-paths-lesson-show",
        component: () =>
          import("@/views/crafted/training/learning-paths/Lesson/LessonShow.vue"),
        props: (route) => ({ id: route.params.id, lesson_id: route.params.lesson_id }),
      },
      {
        path: "/training/learning-paths/:id/machine/:machine_id",
        name: "learning-paths-machine-show",
        component: () =>
          import("@/views/crafted/training/learning-paths/Machine/MachineShow.vue"),
        props: (route) => ({ id: route.params.id, machine_id: route.params.machine_id }),
      },
      {
        path: "/training/learning-paths/:id/challenge/:challenge_id",
        name: "learning-paths-challenge-show",
        component: () =>
          import("@/views/crafted/training/learning-paths/Challenge/ChallengeShow.vue"),
        props: (route) => ({ id: route.params.id, challenge_id: route.params.challenge_id }),
      },
      {
        path: "/training/training-machines",
        name: "training-machines",
        component: () =>
          import("@/views/crafted/training/machines/MachinesList.vue"),
      },
      {
        path: "/training/training-machines/:machine_uuid",
        name: "training-machines-show",
        component: () =>
          import("@/views/crafted/training/machines/MachineShow.vue"),
        props: (route) => ({ uuid: route.params.machine_uuid }),
      },
      {
        path: "/training/course-catalog",
        name: "course-catalog",
        component: () =>
          import("@/views/crafted/course/base/Catalog.vue"),
      },
      {
        path: "/training/course/:id",
        name: "training-module-list",
        component: () =>
            import("@/views/crafted/course/module/ModuleList.vue"),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: "/training/modules/:id",
        name: "training-module-show",
        component: () =>
          import("@/views/crafted/course/module/ModuleShow.vue"),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: "/training/modules/:id/lesson/:lesson_id",
        name: "training-module-show-lesson",
        component: () =>
            import("@/views/crafted/course/module/ModuleShow.vue"),
        props: (route) => ({ id: route.params.id, lesson_id: route.params.lesson_id }),
      },
    ],
  },
  {
    path: "/",
    redirect: "/profile/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/profile/overview",
        name: "profile-overview",
        component: () => import("@/views/crafted/account/Account.vue"),
      },
      {
        path: "/profile/certificate",
        name: "profile-certificate",
        component: () => import("@/views/crafted/account/Certificate.vue"),
      },
      {
        path: "/profile/settings",
        name: "profile-settings",
        component: () =>
          import("@/views/crafted/account/Settings.vue"),
        props: (route) => ({ uuid: route.params.machine_uuid }),
      },
      {
        path: "/profile/billing",
        name: "profile-billing",
        component: () =>
          import("@/views/crafted/account/Billing.vue"),
      },
      {
        path: "/checkout/waiting-payment",
        name: "checkout-waiting-payment",
        component: () =>
          import("@/views/crafted/checkout/Waiting.vue"),
      },
      {
        path: "/profile/billing/renew-subscription",
        name: "renew-subscription",
        component: () =>
          import("@/views/crafted/account/RenewSubscription.vue"),
      }
    ],
  },
  {
    path: "/",
    redirect: "/apps/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/apps/vpn",
        name: "vpn-route",
        component: () => import("@/views/apps/vpn/core.vue"),
      },
      {
        path: "/apps/global-search",
        name: "global-search",
        component: () => import("@/views/apps/global-search/search.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/club/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/club/leaderboard",
        name: "leaderboard-route",
        component: () => import("@/views/apps/leaderboard/core.vue")
      },
      {
        path: "/club/feed",
        name: "feed",
        component: () => import("@/views/apps/feed/core.vue"),
      },
      {
        path: "/club/feed/:id",
        name: "feed-view",
        component: () => import("@/views/apps/feed/feed-view.vue"),
        props: (route) => ({ id: route.params.id }),
      }
    ],
  },
  {
    path: "/",
    redirect: "/profile/",
    component: () => import("@/layout/LayoutPublic.vue"),
    children: [
      {
        path: "/profile/user/:id",
        name: "profile-user",
        component: () => import("@/views/crafted/profile/User.vue"),
        props: (route) => ({ id: route.params.id })
      },
    ],
  },
  {
    path: "/",
    redirect: "/certificate/",
    component: () => import("@/layout/LayoutCertificate.vue"),
    children: [
      {
        path: "/certificate/validate",
        name: "certificate",
        component: () => import("@/views/crafted/certificate/CertificateIndex.vue"),
      },
      {
        path: "/certificate/:id",
        name: "certificate-view",
        component: () => import("@/views/crafted/certificate/CertificateView.vue"),
        props: (route) => ({ id: route.params.id }),
      }

    ],
  },
  {
    path: "/",
    redirect: "/certifications/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/certifications",
        name: "certifications",
        component: () => import("@/views/crafted/certification/CertificationIndex.vue"),
      },
      {
        path: "/certifications/to-do",
        name: "certifications-to-do",
        component: () => import("@/views/crafted/certification/CertificationToDo.vue"),
      },
      {
        path: "/certifications/sending-report",
        name: "certifications-sending-report",
        component: () => import("@/views/crafted/certification/CertificationSendingReport.vue"),
      },
      {
        path: "/certifications/feedback",
        name: "certifications-feedback",
        component: () => import("@/views/crafted/certification/CertificationFeedback.vue"),
      },
      {
        path: "/certifications/certifications-machines/:id",
        name: "certifications-machines-show",
        component: () =>
            import("@/views/crafted/certification/machines/MachineShow.vue"),
        props: (route) => ({ uuid: route.params.id }),
      },
    ]
  },
  {
    path: "/",
    redirect: "/podium/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/podium",
        name: "podium",
        component: () => import("@/views/crafted/podium/PodiumIndex.vue"),
      }
    ]
  },  
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((ctx) => {


  const whiteListRoutes = [
    "sign-in",
    "sign-up",
    "password-reset",
    "oauth-provider",
    "oauth-provider-callback",
    "privacy-and-terms",
    "verify-email",
    "password-reset-validation",
    "certificate",
    "certificate-view"
  ];

  const currentRoutename = <string>ctx.name;

  if (!whiteListRoutes.includes(currentRoutename)) {
    store.dispatch(Actions.VERIFY_AUTH);
  }
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);



  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
