import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface VPN {
  vpn_user_id: string;
  vpn_virtual_ip: string;
  vpn_online: boolean;
  vpn_file: string;
}

@Module
export default class VpnModule extends VuexModule{
  errors = {};
  currentVPNProfile = {} as VPN;


  get getCurrentIpAddress(): any {
    return this.currentVPNProfile.vpn_virtual_ip;
  }

  get getCurrentOnline(): any {
    return this.currentVPNProfile.vpn_online;
  }
  get getCurrentVPN(): any {
    return this.currentVPNProfile;
  }


  @Mutation
  [Mutations.SET_VPN_DATA](vpn) {
    this.currentVPNProfile.vpn_virtual_ip = vpn.virtual_ip; 
    this.currentVPNProfile.vpn_online = vpn.online;
    this.currentVPNProfile.vpn_user_id = vpn.user_id;
    this.currentVPNProfile.vpn_file = vpn.vpn_file;
  }

  @Action
   [Actions.GET_VPN_CONFIG]() {
   
    ApiService.get("/vpn").then(data => {
        
        if(data.data.success){
            this.context.commit(Mutations.SET_VPN_DATA, data.data.vpn); 
        }
    }).catch(error => {
        ApiService.post("/vpn", {}).then(res => {
            this.context.commit(Mutations.SET_VPN_DATA, res.data.vpn);  
        }).catch(error => {
            console.log(error);
        })
    })   
    
  }  

}
