import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
export interface Plan {
  id: number,
  name: string,
  value_cents: number
}

export interface Pix {
  pix: string,
  text: string
}
export interface CheckoutInfo {
  plan: unknown;
  boletoUrl: string,
  pix: Pix
}
export interface Installments {
  [key: string]: string;
}

@Module
export default class CheckoutModule extends VuexModule implements CheckoutInfo {
  errors: {} = {};
  plan = {} as Plan;
  boletoUrl = '#'
  pix = {} as Pix
  installments = {};

  get getPlan(): Plan {
    return this.plan;
  }

  get getBoletoUrl(): String {
    return this.boletoUrl;
  }

  get getPix(): Pix {
    return this.pix;
  }

    get getInstallmentData(){
      return this.installments;
    }

  @Mutation
  setPlan(plan) {
    this.plan = plan;
  }

  @Mutation
  setBoletoUrl(url) {
    this.boletoUrl = url;
  }

  @Mutation
  setPix(pix:Pix) {
    this.pix = pix;
  }

  @Action
  async loadPlan() {
    try{
      const planResponse= await ApiService.get('/plan/index');
      const plan = planResponse.data.filter(plan => plan.identifier === 'premium')[0]

      this.context.commit('setPlan', plan)
    }catch(error){
     
      throw new Error(" Error on get latest order status");
    }
  }

  @Action
  async updateBoletoUrl(url) {
    this.context.commit('setBoletoUrl', url)
  }

  @Action
  async updatePix(pix:Pix) {
    this.context.commit('setPix', pix)
  }

  @Action
  async [Actions.GET_INSTALLMENTS](id: number) {
    try {
      const response: any = await ApiService.get('/charge/installments/'+id);
      this.context.commit(Mutations.SET_INSTALLMENTS_DATA, response.data);
    } catch (error) {
      this.context.commit(Mutations.SET_INSTALLMENTS_DATA, {});
      this.errors = error;
    
    }
  }

  @Mutation
  [Mutations.SET_INSTALLMENTS_DATA](installments) {
    this.installments = installments;
  }
}
