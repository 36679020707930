import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";

@Module
export default class LanguageModule extends VuexModule {
    currentLanguage: string = localStorage.getItem('currentLanguage') || 'en';
    private languages = {
        lang_pt: 'pt',
        lang_en: 'en',
        lang_es: 'es'
    };

    get getCurrentLanguage(): string {
        return this.currentLanguage;
    }
    get langPT(): string {
        return this.languages.lang_pt;
    }

    get langEN(): string {
        return this.languages.lang_en;
    }

    get langES(): string {
        return this.languages.lang_es;
    }

    @Action
    async [Actions.CHANGE_LANGUAGE](newLanguage: string): Promise<void> {
       this.context.commit(Mutations.SET_CHANGE_LANGUAGE, newLanguage);
    }

    @Mutation
   [Mutations.SET_CHANGE_LANGUAGE](newLanguage: string): void {
        this.currentLanguage = newLanguage;
        localStorage.setItem('currentLanguage', newLanguage);
    }
}