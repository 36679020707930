import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface INotification {
  body: string;
  title: string;
  is_read: boolean;
  id: number;
  created_at: string;
  updated_at: string;
  notifiable: {
    id: number;
    headline: string;
  },
  notifiable_type: string;
  type: string;
}

@Module
export default class Notification extends VuexModule{
  errors = {};
  currentNotifications  = [] as INotification[];


  get getCurrentNotifications(): any {
    return this.currentNotifications;
  }

  get getCurrentNotificationsCount(): any {
    return this.currentNotifications.filter((notification: INotification) => !notification.is_read).length;
  }

  @Mutation
  [Mutations.SET_NOTIFICATIONS](notifications) {
    this.currentNotifications = notifications;
  }

  @Action
  [Actions.MARK_AS_READ](notificationId) {

    ApiService.put(`/notifications/${notificationId}/mark-as-read`, {}).catch(error => {
        console.log(error)
    })

  }

  @Action
  [Actions.MARK_ALL_AS_READ]() {
    ApiService.put(`/notifications/mark-all-as-read`, {}).catch(error => {
        console.log(error)
    })
  }

  @Action
   [Actions.GET_ALL_NOTIFICATIONS]() {
   
    ApiService.get("/notifications").then(data => {
        
        if(data.data.data){
            this.context.commit(Mutations.SET_NOTIFICATIONS, data.data.data); 
        }
    }).catch(error => {
        console.log(error)
    })   
    
  }  

}
