import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import type {UserType} from "@/types/UserType";

@Module
export default class UserPublicModule extends VuexModule {
    errors = {};
    user = {} as UserType;

    /**
     * Get public user object
     * @returns User
     */
    get publicUser(): UserType {
        return this.user;
    }

    @Action
    async [Actions.GET_PUBLIC_USER](id: number) {
        try {
         
            const response: any = await ApiService.get('/account/profile/public/'+id['id']);
           this.context.commit(Mutations.SET_PUBLIC_USER, response.data.data);
        } catch (error) {
            this.context.commit(Mutations.SET_PUBLIC_USER, {});
            this.errors = error;
            
        }
    }

    @Mutation
    [Mutations.SET_PUBLIC_USER](user: UserType) {
        this.user = user;
    }


}