enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  LOGIN_SSO = "loginSSO",
  GET_MACHINES = "getMachines",
  GET_MACHINE = "getMachine",
  GET_USER_INSTACE = "getUserInstance",
  GET_MACHINE_ACTIVITY = "getMachineActivity",
  GET_CHECKOUT_ITEMS = "getCheckoutItems",
  GET_CHECKOUT_PLANS = "getCheckoutPlans",
  GET_CARD_TOKEN = "getCardToken",
  GET_ALL_COURSES = "getAllCourses",
  GET_COURSE = "getCourse",
  GET_MODULE_ALL = "getModuleAll",
  GET_MODULE = "getModule",
  GET_VPN_CONFIG = "getVPNConfig",
  GET_MACHINES_FILTERED = "getMachinesFiltered",
  UPDATE_CURRENT_ACTIVE_CHECKOUT = "updateCurrentActiveCheckout",
  GET_LATEST_ORDER_STATUS = "getLatestOrderStatus",
  GET_ALL_FEEDS = "getAllFeed",
  REACT = "react",
  COMMENT = "comment",
  DELETE_COMMENT = "deleteComment",
  GET_ALL_NOTIFICATIONS = "getAllNotifications",
  MARK_AS_READ = "markAsRead",
  MARK_ALL_AS_READ = "markAllAsRead",
  GET_LESSON_ALL = "getLessonAll",
  GET_LESSON = "getLesson",
  UPDATE_WATCHED_CHECK = "updateWatchedCheck",
  ADD_HACKTIVITY = "addHacktivity",
  GET_SCORE_SYSTEM = "getScoreSystem",
  GET_PUBLIC_USER = "getPublicUser",
  GET_ALL_MACHINE = "getAllMachines",
  GET_ALL_CHALLENGES = "getAllChallenges",
  GET_CHALLENGE = "getChallenge",
  DELETE_HACKTIVITY = "deteleHacktivty",
  GET_INSTALLMENTS = "getInstallments",
  GET_FEED = "getFeedShow",
  FIXED_HACKTIVITY = "fixedHacktivity",
  FEED_SHOW = "feedShow",
  CHANGE_LANGUAGE = "changeLanguage",
  REFUND_INVOICE = "refundInvoice",
  VERIFY_CERTIFICATE = "verifyCertificate",
  SET_COUNTDOWN_ACTION = "setCountdownAction",
  ADD_SEND_REPORT = "addSendReport",
  GET_MACHINE_CERTIFICATION = "getMachineCertification",
  GET_PATHWAYS = "getPathways",
  GET_PATHWAY = "getPathway"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_MACHINES = "setMachinesonStore",
  SET_CURRENT_MACHINE = "setCurrentMachine",
  START_INSTANCE = "startInstance",
  STOP_INSTANCE = "stopInstance",
  SET_CURRENT_MACHINE_ACTIVITY = "setCurrentMachineActivity",
  UPDATE_USER = "updateUser",
  SET_CHECKOUT_ITEMS = "setCheckoutItems",
  SET_CHECKOUT_PLANS = "setCheckoutPlans",
  ADD_ITEM_TO_CART = "addItemToCart",
  CLEAR_CHECKOUT = "clearCheckout",
  SET_CHECKOUT = "setCheckout",
  SET_CARD_DATA = "setCardData",
  SET_CARD_TOKEN = "setCardToken",
  SET_PAYMENT_METHOD = "setPaymentMethod",
  SET_COURSE_DATA = "setCourseData",
  SET_CURRENT_COURSE_DATA = "setCurrentCourseData",
  SET_MODULE_DATA = "setModuleData",
  SET_CURRENT_MODULE_DATA = "setCurrentModuleData",
  SET_INSTALLMENTS = "setInstallments",
  SET_ADDRESS_DATA = "setAddressData",
  SET_VPN_DATA  = "setVpnData",
  SET_MACHINES_METADATA = "setMachinesMetadata",
  SET_CURRENT_MACHINES_LIST = "setCurrentMachinesList",
  SET_CURRENT_COUPON = "setCurrentCoupon",
  SET_ACTIVE_CHECKOUT = "setActiveCheckout",
  SET_FEED = "setFeed",
  SET_LOADING = "setLoading",
  REFRESH_FEED = "refreshFeed",
  REFRESH_REACTIONS = "refreshReactions",
  REFRESH_COMMENTS = "refreshComments",
  REFRESH_COMMENTS_FROM_FEED = "refreshCommentsFromFeed",
  SET_NOTIFICATIONS = "setNotifications",
  SET_LESSON_DATA = "setLessonData",
  SET_CURRENT_LESSON_DATA = "setCurrentLessonData",
  SET_CURRENT_LESSON = "setCurrentLesson",
  SET_LESSON_WATCHED_CHECK = "setLessonWatchedCheck",
  SET_CURRENT_SCORE_SYSTEM_DATA = "setCurrentScoreSystemData",
  SET_SCORE_SYSTEM_DATA = "setScoreSystemData",
  SET_PUBLIC_USER = "setPublicUser",
  SET_CHALLENGES = "setChallenges",
  SET_CHALLENGE = "setChallenge",
  SET_INSTALLMENTS_DATA = "setInstallmentsData",
  REFRESH_PAGINATION = "refreshPagination",
  SET_SHOW_FEED = "setShowFeed",
  SET_CHANGE_LANGUAGE = "setChangeLanguage",
  SET_CERTIFICATE = "setCertificate",
  SET_COUNTDOWN_MUTATION = "setCountdownAction",
  SET_CURRENT_MACHINE_CERTIFICATION = "setCurrentMachineCertification",
  SET_PATHWAYS = "setPathways",
  SET_CURRENT_PATHWAY = "setCurrentPathway"
}

export { Actions, Mutations };
